@import 'styles/mixins/vars-and-mixins';

.HelpText {
  button {
    @include unstyle-button;
  }

  /* stylelint-disable */
  button > * {
    display: inline-block;
    padding-top: 12px;
  }
  /* stylelint-enable */

  i {
    position: relative;
    top: 3px;
    margin-left: 3px;
    font-size: 25px;
  }

  :global(.ReactCollapse--content) {
    @include info-box;

    button {
      float: right;
      position: relative;
      top: 3px;
      margin-right: 0px;
      text-decoration: underline;
    }
  }
}
