$open-path: '../fonts/Open_Sans';

/* Regular */
@font-face {
  font-family: 'OpenSans-Light';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url($open-path+'/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url($open-path+'/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url($open-path+'/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url($open-path+'/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url($open-path+'/OpenSans-ExtraBold.ttf');
}

/* Italic */
@font-face {
  font-family: 'OpenSans-LightItalic';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url($open-path+'/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-Italic';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url($open-path+'/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url($open-path+'/OpenSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url($open-path+'/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url($open-path+'/OpenSans-ExtraBoldItalic.ttf');
}
