@import 'styles/mixins/vars-and-mixins';

/* Wrapping div */
.Search {
  margin-top: 20px;
  position: relative;
  min-height: 40px;
  color: black;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  /* OMS Components wrapper div */
  > div {
    width: 100%;
    height: 100%;

    /* The suggest Wrapper */
    div[role='combobox'] {
      width: 100%;
      height: 100%;
      /* The Input field */
      input[type='text'] {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0);
      }
      /* Suggestions list */
      div[role='listbox'] {
        top: 100%;
        width: 100%;
        box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.2);
      }
      ul[role='listbox'] {
        width: 100%;
        /* Suggestion */
        li[role='option'] {
          border-bottom: 1px solid #eaeaea;
          color: black;
          display: flex;
          &[aria-selected='true'] {
            background-color: rgba(0, 118, 255, 0.12);
          }
          & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* ticker */
            & > :first-child {
              font-size: 16px;
              /* query match*/
              em {
              }
            }
            > :last-child {
              font-weight: 600;
            }
          }
          & > span {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  > svg {
    pointer-events: none;
    position: absolute;
    font-size: 20px;
    right: 14px;
  }
}

.formError {
  color: red;
}

:global {
  /* hover */
  .react-autosuggest__suggestion--highlighted {
  }
  .suggestion-query-match-highlight {
    background-color: rgba(255, 255, 140, 0.5);
  }
}
