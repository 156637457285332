@import 'styles/mixins/vars-and-mixins';

.Portfolios {
  .error {
    @include error-box;
    display: block;
  }
  p {
    font-size: 14px;
  }
}
