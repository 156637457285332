@mixin default-btn {
  /* Rectangle: */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  cursor: pointer;
  background-color: var(--norne-main-color-1);
  color: $white;
  font-family: $default-font;
  letter-spacing: 2px;
  line-height: 1.5;
  text-decoration: none;
  text-transform: none;
  overflow: visible;
  border: 2px solid transparent;
  border-radius: $button-border-radius;
  font-weight: bolder;
  width: fit-content;
  transition: all 0.1s ease-in-out 0s;

  &:focus,
  &:hover {
    color: $white;
    background-color: color($trader-blue blackness(25%));
    box-shadow: 0 0px 0px 2px var(--norne-main-color-1);
  }

  &:active {
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  }

  &:disabled,
  &:disabled:hover {
    color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
    background-color: rgb(232, 232, 231);
    box-shadow: none;
  }
}

@mixin unstyle-button {
  margin: 0;
  padding: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

@mixin small-btn-dense {
  font-size: 14px;
  padding: 4px 12px;
  line-height: 1;
}

@mixin small-circle-dense {
  font-size: 16px;
  padding: 5px 8px;
}

@mixin circle-normal {
  font-size: 19px;
  padding: 8px 9px;
}

@mixin small-btn {
  font-size: 16px;
  padding: 8px 12px;
}

@mixin medium-btn {
  font-size: 20px;
  padding: 12px 16px;
}

@mixin large-btn {
  font-size: 28px;
  padding: 16px 32px;
}

@mixin stretched-btn {
  width: 100%;
  max-width: none;
  flex: 1 0 auto;
}

@mixin primary-btn {
  @include default-btn;
  color: $white;
  background-color: var(--norne-main-color-1);
  &:hover {
    background: color($trader-blue blackness(25%));
    border-color: color($trader-blue blackness(25%));
  }
}

@mixin secondary-btn {
  @include default-btn;
  color: $dark-grey;
  background-color: $white;
  border: 2px solid var(--norne-main-color-1);
  &:hover {
    color: $dark-grey;
    background-color: #eaeaea;
  }

  &:disabled,
  &:disabled:hover {
    color: $dark-grey;
  }
  &:focus {
    color: $dark-grey;
    background-color: color(#eaeaea blackness(25%));
  }
  &:global(.focus-visible) {
    color: $dark-grey;
    background-color: color(#eaeaea blackness(25%));
  }
}

@mixin success-btn {
  @include default-btn;
  background: #49ac30;
  &:hover {
    background-color: color(#49ac30 blackness(25%));
    box-shadow: 0 0px 0px 2px color(#49ac30 blackness(25%));
  }
}

@mixin delete-btn {
  @include default-btn;
  background: #ad0536;
  &:hover {
    background-color: color(#ad0536 blackness(25%));
    box-shadow: 0 0px 0px 2px color(#ad0536 blackness(25%));
  }
}

@mixin warning-btn {
  @include default-btn;
  background: #e0a717;
  &:hover {
    background-color: color(#e0a717 blackness(25%));
    box-shadow: 0 0px 0px 2px color(#e0a717 blackness(25%));
  }
}

@mixin plain-btn {
  @include default-btn;
  background-color: rgba(0, 0, 0, 0);
  color: $dark-grey;
  &:focus,
  &:hover,
  &:global(.focus-visible) {
    color: $dark-grey;
    background-color: #eaeaea;
    box-shadow: 0 0px 0px 2px #eaeaea;
  }
}

@mixin link-btn {
  @include unstyle-button;
  cursor: pointer;
  color: $default-link-color;
  line-height: 14px;
  width: fit-content;
}

@mixin aml-btn {
  @include default-btn;
  padding: 8px 16px !important;
  color: $white;
  background-color: #8d1c39;
  &:hover {
    background: color(#8d1c39 blackness(25%));
    border-color: color(#8d1c39 blackness(25%));
    box-shadow: 0 0px 0px 2px color(#8d1c39 blackness(25%));
  }
}

@mixin traderPrimary {
  @include unstyle-button;
  padding: 10px 20px;
  border: solid 2px #6592cd;
  background-color: var(--norne-main-color-1);
  color: $white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    border: solid 2px #0071f4;
  }
}

@mixin traderSecondary {
  @include traderPrimary;
  background-color: #f2f8ff;
  color: var(--norne-main-color-1);
}

@mixin toggleRadioButton {
  input[type='checkbox'] {
    /* height: 0;
    width: 0;
    visibility: hidden; */
  }
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 43px;
    height: 23px;
    background: #eaeaea;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 19px;
    height: 19px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.5);
  }

  input[type='checkbox']:checked + label {
    background: var(--norne-main-color-1);
  }

  input[type='checkbox']:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 30px;
  }
}
