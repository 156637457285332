.grid {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  grid-gap: 16px;
}

.basic-grid {
  composes: grid;
  grid-gap: 0;
}
