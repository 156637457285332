@import '../external/fontawesome/variables';

/* Typography */
$open-sans: 'Open Sans', sans-serif;
$lato: 'Lato', sans-serif;

/* Normal */
$open-300: 'OpenSans-Light', sans-serif;
$open-400: 'OpenSans-Regular', sans-serif;
$open-600: 'OpenSans-SemiBold', sans-serif;
$open-700: 'OpenSans-Bold', sans-serif;
$open-800: 'OpenSans-ExtraBold', sans-serif;

/* Italic */
$open-i-300: 'OpenSans-LightItalic', sans-serif;
$open-i-400: 'OpenSans-Italic', sans-serif;
$open-i-600: 'OpenSans-SemiBoldItalic', sans-serif;
$open-i-700: 'OpenSans-BoldItalic', sans-serif;
$open-i-800: 'OpenSans-ExtraBoldItalic', sans-serif;

$font-awesome: 'FontAwesome';

/* Subsetted */
$default-font-size: 1em;
$default-line-height: 1, 7;

/* Background Colors */

/* Borders */
$button-border-radius: 4px;

/* Main Colors */
$dark-red: #970831;
$white: #fff;
$dark-grey: #384852;

/* Shadow Colors */
$darker-red: #780626;
$drop-shadow: rgba(0, 0, 0, 0.5);
$left-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 0px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(155, 155, 155, 0.2);

$background-light: #f4f4f4;
$background-white: #272727;
$background-dark: #eaeaea85;
$purple: #9c2aa0;
$better-blue: #00b0ca;
$petrol-grey-light: #7c9da2;
$petrol-green-dark: #496f75;

/* Other Colors */
$darkest-grey: #112129;
$lighter-grey: #dadce0;
$light-grey: #636363;
$marin-blue: #12367f;

$trader-blue2: #4a90e2;
$trader-blue: $trader-blue2;

$salmon-pink: #f3bbaa;
$pastel-grey: #eaeaea;
$pastel-green: #f2faf2;
$pastel-blue: #d3f6fa;
$pastel-pink: #ffd7e1;
$pastel-yellow: #f9fec8;
$caribbean-green: #00cc83; // chir.ag "Name that color" 🤷‍♂️
$polar: #ebfbf5; // chir.ag "Name that color" 🤷‍♂️
$mustard-yellow: #e6bf4a;
$pale-grey: #f5f5f5;
$bleak-red: #c05f5f;
$light-pink: #deafb1;
$teal: #3e7d82;
$gold: #eeaf37;
$success-green: #008a00;
$critical-red: #ad0536;
$button-selected-background: rgba(0, 118, 255, 0.12);

/* Text Colors */
$default-text-color: $dark-grey;
$default-link-color: $trader-blue;
$default-line-height: 1.5;
$default-font-size: 16px;

$default-header: $open-400;
$default-header-font: $open-700;
$default-font: $open-300;

/* Brand colors */
$brand: $trader-blue;
$accent-1: $marin-blue;
$accent-2: $pastel-blue;

$warning: $gold;
$danger: $critical-red;
$success: $success-green;

/* Pos neg flat */
$neg: #e20910;
$neg-light: #ff535e;
$pos: #008a00;
$pos-light: #a3e0a3;
$flat: #555;

/* Active variables */
$primary: $dark-red;
$secondary: $dark-grey;
$tertiary: $white;

/* Animations */
$animation-duration: 150ms;
$animation-duration-seconds: 0.15;
$animation-timing: ease;

/* White space between layout */
$max-size: 1200px !default;

/* Breakpoint */
$xs-screen: 360px;
$small-screen: 420px;
$medium-screen: 768px;
$large-screen: 1800px;

$menu-screen: 1525px;

$breakpoint: $large-screen;

/* Ranges */
$mobile-range: 'max-width: ' + $small-screen;
$small-range: '(min-width: ' + ($small-screen + 1) + ')' + ' and ' +
  '(max-width: ' $medium-screen + ')';
$medium-range: '(min-width: ' + ($medium-screen + 1) + ')' + ' and ' +
  '(max-width: ' $large-screen + ')';
$desktop-range: 'min-width: ' + $large-screen + 1;
$not-mobile: 'min-width: ' + ($small-screen + 1);
$medium-and-mobile: 'max-width: ' + $medium-screen;

$mobile: new-breakpoint(max-width $small-screen) !global;
$small: new-breakpoint($small-range) !global;
$medium: new-breakpoint($medium-range) !global;
$large: new-breakpoint(min-width ($large-screen + 1)) !global;

/* Element variables */
$side-nav-width: 72px;

/* Baseline Grid */
$base: 8px;

/* Size Units */
$size-2: 2px;
$size-4: 4px;
$size-8: 8px;
$size-12: 12px;
$size-16: 16px;
$size-20: 20px;
$size-24: 24px;
$size-28: 28px;
$size-32: 32px;
$size-36: 36px;
$size-40: 40px;
$size-44: 44px;
$size-48: 48px;
$size-52: 52px;
$size-56: 56px;
$size-60: 60px;
$size-64: 64px;
$size-68: 68px;
$size-72: 72px;
$size-76: 76px;
$size-80: 80px;

/* Spacing */
$spacing-xxs: $size-2;
$spacing-xs: $size-4;
$spacing-sm: $size-8;
$spacing-md: $size-16;
$spacing-lg: $size-24;
$spacing-xl: $size-32;
$spacing-xxl: $size-64;
$spacing-xxxl: $size-80;

/* Elevation */
$drop-shadow: rgba(0, 0, 0, 0.5);

/* Table colours, only define unique colours */
$table-text-default-color: $darkest-grey;
$table-hover-color: #f4f9ff;
$table-neutral: #f3f3f4;
$table-up: #cff3e4;
$table-down: #fcd3d4;
$table-mid-up-down: #e6e3dc;
$table-mid-up-neutral: #d5e8e2;
$table-mid-down-neutral: #ebd8da;

/* Borders */
$default-border-color: $lighter-grey;
