@import 'styles/mixins/vars-and-mixins';

@keyframes none {
  100% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes solid {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }
  60% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(-50%);
  }
}

@keyframes solidLeft {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-50%) translateY(-50%);
  }
  60% {
    opacity: 0;
    transform: scale(0) translateX(-50%) translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
}

@keyframes iconPulse {
  0% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

a.IconButton,
button.IconButton:not([aria-label='lukk']) {
  outline: none;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 48px;
  max-height: 48px;
  box-shadow: none;

  &:global(.focus-visible),
  &:hover {
    box-shadow: none;
  }
}

.IconButton:global(.focus-visible)[aria-pressed='true'],
.IconButton[aria-pressed='true']:hover {
  background-color: #e9f1fd;
  color: var(--norne-main-color-1);
}

a.IconButtonTooltip:not([aria-label='lukk'])::after,
button.IconButtonTooltip:not([aria-label='lukk'])::after {
  position: absolute;
  content: attr(aria-label);
  color: white;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
  top: 120%;
  padding: 4px 12px;
  border-radius: 4px;
  left: 50%;
  opacity: 0;
  transform-origin: 0 50%;
  animation: none forwards;
  display: none;
  z-index: 3;
}

a.IconButtonTooltip:hover::after,
a.IconButtonTooltip:global(.focus-visible)::after,
button.IconButtonTooltip:hover::after,
button.IconButtonTooltip:global(.focus-visible)::after {
  animation: solid 400ms forwards;
  display: block;
}

a.IconButtonTooltip:active,
button.IconButtonTooltip:active {
  > svg {
    animation: iconPulse 200ms forwards;
  }
}

a.IconButtonTooltip:active,
button.IconButtonTooltip:active {
  > svg {
    animation: iconPulse 200ms forwards;
  }
}

a.pending,
button.pending {
  .icon {
    animation: spin 2s linear infinite;
  }
}

a.left:hover::after,
button.left:hover::after {
  left: -120%;
  top: 50%;
  animation: solidLeft 400ms forwards;
  display: block;
  z-index: 2;
}

a,
button {
  > span + svg,
  > svg + span {
    margin-left: 12px;
  }
  > svg {
    pointer-events: none;
  }
}

.primary {
  @include primary-btn;
  text-decoration: none;
}

.secondary {
  @include secondary-btn;
  text-decoration: none;
}

.aml {
  @include aml-btn;
  text-decoration: none;
}

.link {
  @include unstyle-button;
  @include link-btn;
  font-weight: bold;
  line-height: 16px;
  text-decoration: underline;
}

.success {
  text-decoration: none;
  @include success-btn;
}

.danger {
  @include delete-btn;
  text-decoration: none;
}

.warning {
  @include warning-btn;
  text-decoration: none;
}

.plain {
  @include plain-btn;
  text-decoration: none;
}

.clean {
  @include unstyle-button;
  text-decoration: none;
}

.small,
button.small {
  @include small-btn;
}

a.medium,
button.medium {
  @include medium-btn;
}

a.large,
button.large {
  @include large-btn;
}

a.stretch,
button.stretch {
  @include stretched-btn;
}

a.center,
button.center {
  margin: 0 auto;
}
