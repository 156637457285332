@import 'styles/mixins/vars-and-mixins';

.Holdings {
  .Heading {
    display: flex;
    align-items: center;
    padding-left: 16px;

    @media (min-width: $medium-screen) {
      border-bottom: 1px solid #eaeaea;
      height: 96px;
      > h2 {
        margin: 0;
        padding-top: 0;
        padding-left: 0;
      }
    }
  }
  .HoldingsNotFound {
    background-color: #f9f9f9;
    padding: 16px;
  }
  .Summary {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 12px 16px;
    background-color: #f9f9f9;
    border-radius: 4px;
    div {
      flex: 1;
      min-width: 50%;
      display: flex;
      flex-direction: column;
      text-align: right;
      &:nth-child(odd) {
        text-align: left;
      }
      &:first-of-type,
      &:nth-of-type(2) {
        margin-bottom: 10px;
      }
    }
    dt {
      font-size: 0.875rem;
      line-height: 1rem;
    }
    dd {
      font-weight: bold;
    }
  }

  .AccountHeading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    h3 {
      font-size: 18px;
      text-align: left;
      padding: 10px 0;
      margin: 0;
      width: calc(100% - 120px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }
  }

  table {
    font-size: 14px;
    /* NOR-2500 Maximize responsiveness */
    table-layout: auto !important;

    th,
    td {
      font-size: inherit;
      padding: 0 5px;
    }

    th:first-child,
    td:first-child {
      padding-left: 8px;
      max-width: 110px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: right;
    }
  }
}

.Divider:not(:last-of-type) {
  background-color: #eaeaea;
  border: 1px solid rgba(151, 151, 151, 0.27);
  padding-top: 5px;
}
