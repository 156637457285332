.FieldContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 24px;

  label {
    display: flex;
    flex-flow: row wrap;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 12px;
    width: 180px;
    align-items: center;

    :global .radio {
      margin: 0;
      margin-right: 4px;
    }
  }

  p {
    flex-basis: 100%; /* Take full width flex row*/

    b {
      color: black;
      font-weight: bolder;
    }
  }

  .errorContainer {
    flex-basis: 100%; /* Take full width of flex row*/
    color: red;

    span {
      margin: 0 0 0 12px;
      display: inline-block;
      font-size: 16px;
      line-height: 1;
    }
  }
}

.Form {
  .buttons {
    display: flex;
    justify-content: center;
    button {
      margin-right: 12px;
    }
  }
}
