@import 'styles/mixins/vars-and-mixins';

.Sidebar {
  display: none;
  width: 0;
  opacity: 0;
  background-color: rgb(255, 255, 255);
  z-index: 2;

  h2 {
    padding-top: 14px;
    padding-left: 14px;
  }

  h2:first-letter {
    text-transform: uppercase;
  }

  transition: opacity 500ms ease, width 500ms ease;
  & > button {
    display: none;
  }

  & > div {
    background: white;
    opacity: 0;
    width: 400px;
    min-width: 400px;
    transform: translateX(400px);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: transform 500ms ease, opacity 500ms ease;
  }
}

.sidebarOpen {
  display: block;
  width: 400px;
  opacity: 1;
  border-left: 1px solid #f1f3f4;
  height: 100%;
  margin-top: 1px;

  & > div {
    transform: translateX(0);
    opacity: 1;
    overflow-y: auto;

    & > * {
      min-height: 100%;
      width: 100%;
    }
  }
}

@media (min-width: 480px) and (max-width: 1625px) {
  .sidebarOpen {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    min-width: 100vw;
    height: 100%;
    min-height: 100%;
    background-color: rgba(22, 23, 26, 0.5);
    padding-top: 64px;

    > button {
      display: block;
      color: grey;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border: none;
      font-size: 32px;
      width: 56px;
      height: 56px;
      border-radius: 0;
      border-bottom-left-radius: 8px;
      padding: 0;
    }
  }
}

@media (max-width: 480px) {
  .sidebarOpen {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    min-width: 100vw;
    height: 100%;
    min-height: 100%;

    & > div {
      width: 100%;
    }
  }
}
