@import 'styles/mixins/vars-and-mixins';

.JumpLink {
  position: absolute;
  top: -64px;
  background-color: darkgray;
  color: white;
  padding: 12px 16px;
  border-bottom-right-radius: 8px;
  z-index: 9000;
  font-size: 20px;
  font-weight: 600;
  transition: top 200ms ease;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  &:focus {
    top: 0;
  }
}

:global(.jump-link) {
  background-color: var(--norne-main-color-1);
  outline: none;
}
