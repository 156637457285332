.FieldWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label.formFieldLabel {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 12px;
  }

  .InputField input {
    padding: 16px 12px;
    font-size: 20px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    border-radius: 4px;
    border: 1px solid #eaeaea;
  }

  /* Helper text / error area */
  .error {
    display: flex;
    align-items: center;
    margin: 8px 4px 0;

    span {
      margin: 0 0 0 12px;
      display: inline-block;
      font-size: 16px;
      line-height: 1;
    }
  }
}

:global {
  /* Error on a specific field */
  .form-error p,
  .form-field-error {
    color: red;
  }
}
