@import 'styles/mixins/vars-and-mixins';

.UpdatePage {
  display: flex;
  align-items: center;

  /* Switch label */
  > label {
    margin-right: 16px;
  }
  > span {
    margin-left: 8px;
    width: 20px;
  }
  > button[data-type='refresh'] {
    font-size: 16px;
  }
}
