@import 'styles/mixins/vars-and-mixins';

.ActiveOrders {
  h3,
  h4 {
    margin: 0;
    margin-bottom: 5px;
    text-align: left;
    padding: 5px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 16px;

  h2 {
    margin: 0;
    padding: 0;
  }

  .reload {
    @include secondary-btn;
    @include small-btn;
  }
}

.filters {
  width: 100%;
  padding: 0 16px;
}

.menuButtons {
  background-color: $pastel-green;
  display: flex;

  button {
    border: none;
    padding: 10px;
    background-color: $pastel-green;
    margin-right: 10px;
    font-weight: bold;
    color: black;
    border-bottom: solid 4px $pastel-green;
  }

  .active {
    border-bottom: solid 4px var(--norne-main-color-1);
  }
}

.card {
  background-color: rgba(0, 118, 255, 0.12);
  color: $dark-grey;
  display: grid;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border-left: solid 4px var(--norne-main-color-1);
  box-shadow: $left-box-shadow;
  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    button {
      margin: 0;
      > span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    h4 {
      padding: 0;
    }

    > b {
      display: block;
    }
  }
}

small {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandedRow {
  border-top: 1px solid;
  margin-top: 5px;
  padding-top: 5px;
  button {
    margin: 5px 5px;
  }

  .buttonGroup {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }
}

.search {
  margin-bottom: 8px;
  display: block;

  input {
    width: 100% !important;
  }
}

.OrdersBadge {
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  padding: 0 9px;
  color: $white;
  font-weight: bold;
  background-color: $dark-red;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 $drop-shadow;
  border: solid 2px red;
}

@media (min-width: $medium-screen) {
  .Heading {
    border-bottom: 1px solid #eaeaea;
    height: 96px;

    > h2 {
      margin: 0;
      padding-top: 0;
      padding-left: 0;
    }
  }
}
