@import 'styles/mixins/vars-and-mixins';

.info {
  @include info-box;
}

.error {
  @include error-box;
}

.warning {
  @include warning-box;
}

.success {
  @include success-box;
}

.inline-info {
  @include info-box;
  display: inline-block;
}

.inline-error {
  @include error-box;
  display: inline-block;
}

.inline-warning {
  @include warning-box;
  display: inline-block;
}

.inline-success {
  @include success-box;
  display: inline-block;
}
