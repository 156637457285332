tbody .Row {
  td.last-visible::after {
    content: '\f078'; /* fa-chevron-down */
    display: inline-block;
    margin: auto 10px;
    font-family: 'FontAwesome';
    transition: 0.3s;
  }

  /* Number cells are right aligned, no need to right align chevron there */

  tbody td.last-visible:not(.number)::after {
    float: right;
  }

  /* Pull chevron to the right */

  td.last-visible {
    display: flex;

    > div {
      flex: 1;
    }
  }

  &.expanded td.last-visible::after {
    transform: rotate(180deg);
  }
}

.Row {
  th:not(.expandedRow) > div,
  td:not(.expandedRow) > div {
    padding: 10px;

    /* The div should not be clickable, force click through to the row */
    pointer-events: none;

    a,
    button {
      pointer-events: all;
    }
  }
}

.Suggest .OmsButton {
  @include unstyle-button;
  padding: 8px;
  height: 100%;
}
