.cell-first-neutral-adjacent-neutral {
  @include gradient-first($table-neutral, $table-neutral);
}
.cell-first-neutral-adjacent-up {
  @include gradient-first($table-neutral, $table-mid-up-neutral);
}
.cell-first-neutral-adjacent-down {
  @include gradient-first($table-neutral, $table-mid-down-neutral);
}
.cell-last-neutral-adjacent-up {
  @include gradient-last($table-mid-up-neutral, $table-neutral);
}
.cell-last-neutral-adjacent-down {
  @include gradient-last($table-mid-down-neutral, $table-neutral);
}
.cell-last-neutral-adjacent-neutral {
  @include gradient-last($table-neutral, $table-neutral);
}

/* cell up */
.cell-middle-up-adjacent-up-up {
  @include gradient($table-up, $table-up, $table-up);
}

.cell-middle-up-adjacent-up-down {
  @include gradient($table-up, $table-up, $table-mid-up-down);
}

.cell-middle-up-adjacent-up-neutral {
  @include gradient($table-up, $table-up, $table-mid-up-neutral);
}

.cell-middle-up-adjacent-down-up {
  @include gradient($table-mid-up-down, $table-up, $table-up);
}

.cell-middle-up-adjacent-down-down {
  @include gradient($table-mid-up-down, $table-up, $table-mid-up-down);
}

.cell-middle-up-adjacent-down-neutral {
  @include gradient($table-mid-up-down, $table-up, $table-mid-up-neutral);
}

.cell-middle-up-adjacent-neutral-up {
  @include gradient($table-mid-up-neutral, $table-up, $table-up);
}

.cell-middle-up-adjacent-neutral-down {
  @include gradient($table-mid-up-neutral, $table-up, $table-mid-up-down);
}

.cell-middle-up-adjacent-neutral-neutral {
  @include gradient($table-mid-up-neutral, $table-up, $table-mid-up-neutral);
}

/* cell neutral */
.cell-middle-neutral-adjacent-up-up {
  @include gradient(
    $table-mid-up-neutral,
    $table-neutral,
    $table-mid-up-neutral
  );
}

.cell-middle-neutral-adjacent-up-down {
  @include gradient(
    $table-mid-up-neutral,
    $table-neutral,
    $table-mid-down-neutral
  );
}

.cell-middle-neutral-adjacent-up-neutral {
  @include gradient($table-mid-up-neutral, $table-neutral, $table-neutral);
}

.cell-middle-neutral-adjacent-down-up {
  @include gradient(
    $table-mid-down-neutral,
    $table-neutral,
    $table-mid-up-neutral
  );
}

.cell-middle-neutral-adjacent-down-down {
  @include gradient(
    $table-mid-down-neutral,
    $table-neutral,
    $table-mid-down-neutral
  );
}

.cell-middle-neutral-adjacent-down-neutral {
  @include gradient($table-mid-down-neutral, $table-neutral, $table-neutral);
}

.cell-middle-neutral-adjacent-neutral-up {
  @include gradient($table-neutral, $table-neutral, $table-mid-up-neutral);
}

.cell-middle-neutral-adjacent-neutral-down {
  @include gradient($table-neutral, $table-neutral, $table-mid-down-neutral);
}

.cell-middle-neutral-adjacent-neutral-neutral {
  @include gradient($table-neutral, $table-neutral, $table-neutral);
}

/* cell down */
.cell-middle-down-adjacent-up-up {
  @include gradient($table-mid-up-down, $table-down, $table-mid-up-down);
}

.cell-middle-down-adjacent-up-down {
  @include gradient($table-mid-up-down, $table-down, $table-down);
}

.cell-middle-down-adjacent-up-neutral {
  @include gradient($table-mid-up-down, $table-down, $table-mid-down-neutral);
}

.cell-middle-down-adjacent-down-up {
  @include gradient($table-down, $table-down, $table-mid-up-down);
}

.cell-middle-down-adjacent-down-down {
  @include gradient($table-down, $table-down, $table-down);
}
.cell-middle-down-adjacent-down-neutral {
  @include gradient($table-down, $table-down, $table-mid-up-down);
}

.cell-middle-down-adjacent-neutral-up {
  @include gradient($table-mid-down-neutral, $table-down, $table-mid-up-down);
}

.cell-middle-down-adjacent-neutral-down {
  @include gradient($table-mid-down-neutral, $table-down, $table-down);
}

.cell-middle-down-adjacent-neutral-neutral {
  @include gradient(
    $table-mid-down-neutral,
    $table-down,
    $table-mid-down-neutral
  );
}
