@import 'styles/mixins/vars-and-mixins';

$left-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 0px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(155, 155, 155, 0.2);

.InstrumentOverview {
  background-color: #f8f8f8;
  box-shadow: $left-box-shadow;
  display: flex;
  padding: 0px 16px;
  box i {
    font-size: 30px;
    position: relative;
  }

  i:global(.pos) {
    color: $success-green;
    top: 3px;
  }

  i:global(.neg) {
    color: #e10a0f;
    transform: rotate(180deg);
    top: 8px;
  }

  .row {
    padding: 5px 5px 5px 0px;

    span {
      margin-left: 10px;
      color: $default-text-color;
      font-weight: bold;
    }

    span:first-of-type {
      margin-left: 0px;
      padding-right: 5px;
    }

    b {
      font-size: 28px;
    }

    span:global(.neg) {
      color: #e10a0f;
      font-size: 16px;
    }
    span:global(.pos) {
      color: $success-green;
      font-size: 16px;
    }
  }

  > div {
    flex: 1;
  }
  .chart {
    flex: 2;
  }
}
