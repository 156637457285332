@import 'styles/mixins/vars-and-mixins';

.accordion {
  position: relative;
  width: 100%;

  > header {
    cursor: pointer;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }
  }
}

.closeButton {
  @include unstyle-button;

  position: absolute;
  top: $size-8;
  right: $size-12;
}

.collapsable {
  margin-top: $size-12;
}
