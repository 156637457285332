@import 'styles/mixins/vars-and-mixins';

.Switch {
  /* The switch button */
  width: 50px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  background-color: grey;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  font-size: 9px;
  font-weight: 800;
  padding: 0;
  margin: 0;

  div:focus {
    border: solid orange;
  }

  /* Button content */
  > div {
    display: flex;
    align-items: center;
    height: 22px;
    transform: translateX(-25px);
    transition: all 200ms ease;
    box-sizing: border-box;

    /* Inner switch labels */
    > span:first-child,
    > span:last-child {
      flex-shrink: 0;
      width: 27px;
      text-align: center;
      user-select: none;
    }

    /* Ball */
    > div {
      flex-shrink: 0;
      background-color: white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        font-size: 12px;
        transform: translateX(1px);
      }
    }
  }
}

/* With component <input /> */
.SwitchInputWrapper {
  width: 50px;
  height: 24px;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    z-index: 1;
    appearance: none;
    background: none;
  }
  input:hover {
    cursor: pointer;
  }
  input:focus {
    border: solid 1px;
  }
}

/* Switch on */
.SwitchInputWrapper input:checked + div {
  > div {
    transform: translateX(0);
  }
}

.Switch[aria-checked='false'] div:first-child {
  transform: translateX(-25px);
}

.Switch[aria-checked='true'] div:last-child {
  transform: translateX(0);
}

/* Switch disabled */
.SwitchInputWrapper input:disabled + div,
.Switch[disabled]:last-child,
.Switch[disabled]:first-child {
  opacity: 0.5;
}

:global {
  .switch {
  }
  .switch-on {
    color: white;
    background-color: var(--norne-main-color-1);
  }

  .switch-off {
    color: var(--norne-main-color-1);
    background-color: #eaeaea;
  }

  .switch-off .switch-ball {
    svg {
      color: darkgray;
    }
  }

  .switch-on .switch-ball {
    svg {
      color: var(--norne-main-color-1);
    }
  }
}
