@mixin generic-box {
  margin: 10px 0px;
  padding: 15px;
  color: #000;
  font-family: $default-font;
  text-align: left;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@mixin info-box {
  @include generic-box;
  background-color: $pastel-blue;
  border-left: solid #00529b 5px;
}

@mixin error-box {
  @include generic-box;
  background-color: $pastel-pink;
  border-left: solid $dark-red 5px;

  p {
    font-size: 25px;
  }
}

@mixin warning-box {
  @include generic-box;
  background-color: $pastel-yellow;
  border-left: solid $mustard-yellow 5px;
}

@mixin success-box {
  @include generic-box;
  background-color: $polar;
  border-left: solid $caribbean-green 5px;
}
