@import 'styles/mixins/vars-and-mixins';

.HelpText {
  padding-bottom: 10px;
  color: #0084f8;

  button {
    @include unstyle-button;
    text-decoration: underline;
  }

  /* stylelint-disable */
  button > *:not(:global(.fa)) {
    display: inline-block;
    padding-top: 12px;
  }
  /* stylelint-enable */

  :global(.ReactCollapse--content) {
    @include info-box;

    button {
      float: right;
      position: relative;
      top: 3px;
      margin-right: 0px;
      text-decoration: underline;
    }
  }
}

.caret {
  margin-right: 8px;
  transition: transform 0.25s;
}
