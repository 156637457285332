html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0px;
  padding-bottom: 70px;
}

table {
  width: 100%;
  table-layout: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

p {
  margin-bottom: 20px;
}

table td,
table th {
  text-align: left;
}

main {
  max-width: var(--content-max-width);
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  flex: 1 0 auto;

  @media (max-width: 480px) {
    max-width: 480px;
    width: 100vw;
    margin-top: 20px;
    margin-left: 0;
    margin-right: auto;
    padding: 0;
    flex: 1 0 auto;
  }
}

table > caption {
  color: $light-grey;
  border: 1px solid $light-grey;
  border-radius: 3px;
}

label > select,
input {
  font-size: 14px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

input[type='number'] {
  appearance: textfield;
}

input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='search'] {
  /* 22px = left + right padding and borders */
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid $light-grey;
  border-radius: 3px;
  font-size: 16px;
}

table td.number,
table th.number {
  justify-content: right;
  text-align: right;
}

select {
  margin-left: 10px;
  padding: 10px 30px 10px 10px;
  background: $white;
  background-image: url('../../images/chevron-down.svg');
  background-position: right 10px top 8px;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border: 1px solid $light-grey;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

abbr {
  text-decoration: none;
}
