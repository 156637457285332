@import 'styles/mixins/vars-and-mixins';

$left-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 0px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(155, 155, 155, 0.2);

.FormHeader {
  display: flex;
  align-items: baseline;
  width: 100%;

  h3,
  p {
    margin: 0px 5px;
  }
  button {
    margin-left: auto;
    font-size: 16px;
    margin-right: 8px;
    height: 56px;
  }
}

.TradeForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $medium-screen) and (min-width: $small-screen) {
    width: 90%;
    margin: auto;
  }

  header {
    background-color: #f8f8f8;
    padding-top: 5px;

    .active {
      background-color: transparent;
      color: white;

      & + div {
        transform: translateX(100%);
      }
    }
  }

  form {
    box-shadow: $left-box-shadow;
    padding: 5px 15px;
    flex: 1;
    > div {
      padding-top: 5px;
    }

    .textWithToggle {
      width: 100%;
      display: flex;
      padding-top: 10px;

      span {
        line-height: 0px;
      }

      > :global(.switch) {
        margin-left: auto;
      }
    }
    .formError {
      color: red;
      display: block;
    }
    .formError + .formError {
      /* spacing if multiple errors exist */
      margin-top: 12px;
    }
    .submitLoadingText {
      margin-top: 12px;
      margin-bottom: 30px;
    }

    input[type='text'],
    input[type='number'] {
      text-align: right;
      width: 100%;
    }
    input[lang='nb'] {
      width: 75px;
    }

    input[type='number']::placeholder {
      color: #aaa;
    }
    input[type='text']:focus::placeholder,
    input[type='number']:focus::placeholder {
      /* effectively hides the placeholder on focus */
      color: transparent;
    }

    fieldset {
      span {
        padding-top: 10px;
        border: 1px solid #636363;
        border-radius: 3px;
      }
    }

    :global(.DayPickerInput) {
      display: block;
      input {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px;
        border: 1px solid #636363;
        border-radius: 3px;
        text-align: right;
        font-size: 16px;
      }
    }
    :global(.DayPickerInput-Overlay) {
      z-index: 99;
    }

    button[type='reset'],
    button[type='submit'] {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .divider {
    background-color: #eaeaea;
    border: 1px solid rgba(151, 151, 151, 0.27);
    margin-top: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .Summary {
    font-size: 12px;

    table {
      border-collapse: collapse;
    }

    td {
      padding-top: 10px;
    }

    tbody {
      tr:first-child {
        border-bottom: dotted 1px #384852;
      }
    }

    td:last-child {
      text-align: right;
      width: 100px;
      color: #384852;
    }
    tfoot {
      font-size: 18px;
      font-weight: bold;

      tr {
        border-top: 1px solid;
      }
    }
  }
  pre {
    background-color: black;
    color: chartreuse;
  }
  .infoMessage {
    background-color: #eaeaea;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 10px;
    padding: 15px;
    p {
      color: #000;
      margin: 0px;
    }
    .competencyTestButton {
      margin: 20px auto;
    }
  }
  .receipt {
    background-color: var(--norne-main-color-1);
    margin-top: 30px;
    margin-bottom: 10px;
    color: $white;
    padding: 15px;
  }
}

.helpText {
  h3 {
    font-size: 16px;
    font-family: 'OpenSans-Light', sans-serif;
    font-weight: 400;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  i {
    position: relative;
    top: 0px;
    margin-left: 2px;
    font-size: 14px;
    color: rgba(95, 110, 120, 1);
    font-weight: 400;
  }

  :global(.ReactCollapse--content) {
    border-left: solid #549ae6;
    background-color: #f5f9fa;
    box-shadow: inherit;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  button > * {
    padding-top: 0px;
  }
}

.infoBox {
  @include info-box;
}

.buyAndSellButton {
  position: relative;
  margin: 10px 16px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-around;
  border: solid 2px var(--norne-main-color-1);
  border-radius: 4px;

  > div {
    position: absolute;
    height: calc(100% - 0px);
    width: calc(50% - 0px);
    top: 0px;
    left: 0;
    background-color: var(--norne-main-color-1);
    box-shadow: 2px 0px 4px 0 rgba(0, 0, 0, 0.14),
      -2px 0px 4px 0 rgba(0, 0, 0, 0.14);
  }

  button {
    transition: all 40ms ease;
    z-index: 1;
    color: #384852;
    background-color: transparent;
    border: none;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
}

.TradeFormSearch {
  width: 100%;
}
