.bm-burger-button {
  /* Position and sizing of burger button */
  position: absolute;
  top: 110px;
  left: 20px;
  width: 36px;
  height: 30px;
}

.bm-burger-bars {
  /* Color/shape of burger icon bars */
  background: $dark-grey;
}

.bm-cross-button {
  /* Position and sizing of clickable cross button */
  width: 24px;
  height: 24px;
}

.bm-cross {
  /* Color/shape of close button cross */
  background: #bdc3c7;
}

.bm-menu {
  /* General sidebar styles */
  padding: 2.5em 1.5em 0;
  color: $light-pink;
  font-size: 1.15em;
  background: $dark-grey;
}

.bm-morph-shape {
  /* Morph shape necessary with bubble or elastic */
  fill: $dark-grey;
}

.bm-item-list {
  /* Wrapper for item list */
  padding: 0.8em;
  color: #b8b7ad;
}

.bm-overlay {
  /* Styling of overlay */
  background: rgba(0, 0, 0, 0.3);
}
