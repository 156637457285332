html {
  --content-max-width: 1800px;

  /* See https://norne.no/profilmanual */
  --norne-main-color-1: #02304b;
  --norne-main-color-2: #f76c6c;
  --norne-supporting-color-1: #f7f8f9;
  --norne-supporting-color-2: #e8e8e8;
  --norne-supporting-color-3: #f2ede9;
  --norne-supporting-color-4: #2a2a2a;
  --norne-supporting-color-5: #507d03;

  --savings-robot-selected: #f76c6c;
}
