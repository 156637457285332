@mixin spinner {
  display: inline-block;
  vertical-align: middle;

  &:before {
    content: ' ';
    visibility: visible;
    display: inline-block;
    width: 20px;
    height: 20px;
    animation: spindindust 1s linear infinite;
    border: 5px solid $dark-red;
    border-radius: 50%;
    border-top-color: transparent;
  }
}

@keyframes spindindust {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
