@import 'styles/mixins/vars-and-mixins';

.FictivePortfolio {
  width: 100%;
  display: flex;
  flex-direction: column;

  header {
    background-color: $pastel-green;
    display: flex;

    button {
      border: none;
      padding: 10px;
      background-color: $pastel-green;
      margin-right: 10px;
      font-weight: bold;
      color: black;
      border-bottom: solid 4px $pastel-green;
    }

    .active {
      border-bottom: solid 4px var(--norne-main-color-1);
    }
  }
}
