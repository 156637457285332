.Summary {
  font-size: 12px;
  margin-top: 20px;

  table {
    border-collapse: collapse;
  }

  td {
    padding-top: 10px;
  }

  tbody {
    tr:first-child {
      border-bottom: dotted 1px #384852;
    }
  }

  td:last-child {
    text-align: right;
    width: 100px;
    color: #384852;
  }
  tfoot {
    font-size: 18px;
    font-weight: bold;

    tr {
      border-top: 1px solid;
    }
  }
}
