@import 'styles/mixins/vars-and-mixins';

.loading {
  display: block;
  vertical-align: middle;
  position: relative;
  height: 100%;
  min-height: 200px;
  text-align: center;

  i {
    @include spinner;

    margin-top: 65px;

    &:before {
      width: 50px;
      height: 50px;
    }
  }

  &.blue {
    i::before {
      border-color: var(--norne-main-color-1);
      border-top-color: transparent;
    }
  }

  &.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;

    background-color: rgba(255, 255, 255, 0.5);

    > i {
      margin: 0;
    }
  }
}
