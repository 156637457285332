@import 'styles/mixins/vars-and-mixins';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    background: white;
    height: 95%;
    max-width: 720px;

    > div {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    @media (max-width: $medium-screen) {
      width: 95%;
      > div {
        margin-top: 48px;
        height: calc(100% - 48px);
      }
      .close {
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
  }

  .close {
    position: absolute;
    right: 24px;
    top: 16px;
  }
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
