@import 'styles/mixins/vars-and-mixins';

/* Inspired by the material design floating action button */

.ToTheTopButton {
  position: fixed;
  right: 20px;
  bottom: 75px;
  padding: 0px 13px 4px;
  color: $white;
  font-size: 30px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 33px;
  transition: all 0.3s ease;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: visibility 0s, opacity 0.1s linear;

  visibility: visible;
  opacity: 1;

  &:active {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
      0px 12px 17px 2px rgba(0, 0, 0, 0.14),
      0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }

  @media print, (min-width: $medium-screen) {
    display: none;
  }
}

.displacedButton {
  bottom: 155px;
}

.showScrollButtonHidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0s, visibility 0.1s linear;
}
