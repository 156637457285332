@import 'styles/mixins/vars-and-mixins';

.Alarms {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global(.OmsButton) {
    height: 100%;
  }

  .textWithToggle {
    position: relative;
    margin: 35px 35px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border: solid 2px var(--norne-main-color-1);
    border-radius: 50px;

    > div {
      position: absolute;
      height: calc(100% - 4px);
      width: calc(50% - 2px);
      top: 0;
      left: 0;
      margin: 2px;
      background-color: var(--norne-main-color-1);
      transition: all 200ms ease;
      border-radius: 50px;
      border: solid 2px var(--norne-main-color-1);
      box-shadow: 2px 0px 4px 0 rgba(0, 0, 0, 0.14),
        -2px 0px 4px 0 rgba(0, 0, 0, 0.14);
    }

    button {
      transition: all 40ms ease;
      z-index: 1;
      color: #384852;
      background-color: white;
      border: none;
      font-weight: bold;
      text-align: center;
    }

    button[aria-pressed='true'] {
      background-color: var(--norne-main-color-1);
      color: white;

      & + div {
        transform: translateX(100%);
      }
    }
  }

  .statusField {
    p {
      display: block;
      @include info-box;
    }
  }

  header {
    background-color: $pastel-green;
    display: flex;
    padding-left: 10px;

    button {
      border: none;
      padding: 10px;
      background-color: $pastel-green;
      margin-right: 10px;
      font-weight: bold;
      color: black;
      border-bottom: solid 4px $pastel-green;
    }

    .active {
      border-bottom: solid 4px var(--norne-main-color-1);
    }
  }
  form {
    padding: 15px;

    .field {
      padding: 5px 0px;
    }

    input {
      width: 100%;
    }

    > label {
      font-weight: bold;
    }

    .error {
      color: red;
    }

    .buttons {
      button[data-submitting='true']:disabled {
        cursor: progress;
      }
      button {
        margin-top: 20px;
      }
    }
  }
  article {
    @include info-box;
    padding: 20px;
  }
}
