@import 'styles/mixins/vars-and-mixins';

.Favorites {
  caption {
    border: none;
  }

  .Heading {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  > p {
    margin-top: -2px;
    background-color: var(--norne-main-color-1);
    color: white;
    padding: 20px;

    a {
      color: white;
      text-decoration: underline;
    }
    svg,
    i {
      color: white;
    }
  }

  button {
    &:hover {
      background-color: $table-hover-color;
    }

    &:after {
      top: 115%;
      left: -40%;
      z-index: 1;
      padding: 10px;
    }
  }

  > table tbody tr > :last-child,
  > table thead tr > :last-child {
    padding-right: 0;
    padding-left: 16px;
    width: 60px;
  }
  abbr {
    text-decoration: none;
  }
}

@media (min-width: $medium-screen) {
  .Favorites .Heading {
    border-bottom: 1px solid #eaeaea;
    height: 96px;

    > h2 {
      margin: 0;
      padding-top: 0;
      padding-left: 0;
    }
  }
}
