@import 'styles/mixins/vars-and-mixins';

.FictivePortfolioForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px;
  padding: 12px;

  .statusField {
    @include info-box;
    margin-top: 20px;
  }
  label {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 12px;
  }

  input {
    padding: 16px 12px;
    font-size: 20px;
    display: block;
    width: 100%;
    min-width: 0;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 20px;
  }
  /* Helper text / error area */
  > div {
    display: flex;
    align-items: center;
    margin: 8px 4px 0;

    span {
      margin: 0 0 0 12px;
      display: inline-block;
      font-size: 16px;
      line-height: 1;
    }
  }
}
