@import 'styles/mixins/vars-and-mixins';

$left-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 0px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(155, 155, 155, 0.2);

.wrapper {
  box-shadow: $left-box-shadow;
  background-color: $pastel-green;
  padding: 0px 16px;

  .showMore {
    display: flex;
    justify-content: center;
  }

  .row {
    width: 100%;
    display: flex;

    .leftBar,
    .rightBar {
      width: 50%;
      display: flex;
      justify-content: space-between;
      position: relative;

      b {
        padding: 6px 6px;
        z-index: 1;
        font-weight: 600;
        font-size: 16px;
      }
      b:last-of-type {
        text-align: right;
      }
    }

    .rightBar {
      background-color: #eaeaea;
      border-left: solid 1px #9a99a2;
    }

    .leftBar {
      background-color: #eaeaea;
      border-right: solid 1px #9a99a2;
    }
    .barBid,
    .barAsk {
      position: absolute;
      height: 100%;
      transition: width 0.5s ease;
      opacity: 0.5;
    }

    .barBid {
      right: 0;
      background-color: #008a00;
    }
    .barAsk {
      left: 0;
      background-color: #e60000;
    }
  }
}
