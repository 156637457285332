@import 'styles/mixins/vars-and-mixins';

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes solid {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes none {
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.Checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 12px;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0;
    box-sizing: border-box;
    appearance: none;
    outline: none;
  }
  input::after {
    position: absolute;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: -50%;
    top: -50%;
    height: 200%;
    width: 200%;
    border-radius: 50%;
    opacity: 0;
    transition: transform 100ms ease;
    animation: none forwards;
  }
  input:disabled {
    cursor: not-allowed;
  }

  input:active::after {
    animation: pulse 200ms forwards;
  }
  input:global(.focus-visible)::after {
    animation: solid 200ms forwards;
  }
}

.small {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.medium {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.large {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

:global {
  .checkbox-on {
    color: var(--norne-main-color-1);

    input::after {
      background-color: #4a90e232;
    }
  }
  .checkbox-off {
  }
  .checkbox-disabled {
    color: $light-grey;
    cursor: not-allowed;
  }
}
