@import 'styles/mixins/vars-and-mixins';

.CompetencyAssessmentTest {
  padding: $size-20;

  .question {
    margin-bottom: $size-20;
    margin-top: $size-20;
  }

  form {
    label {
      input {
        margin-right: $size-20;
      }
    }

    button {
      margin-top: 20px;
    }
  }

  i.icon-text {
    margin-right: 5px;
  }
}

.Results {
  .warningAcknowledge {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    label {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      :global .check-box {
        margin: 0;
        margin-right: 4px;
      }
    }
  }
}
