body {
  color: $default-text-color;
  font-family: $default-font;
  font-size: $default-font-size;
  line-height: $default-line-height;
}

* {
  font-family: $default-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-grey;
  font-family: $default-header;
}

small,
p {
  color: $default-text-color;
}

h1,
h2,
h3 {
  text-align: center;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 24px;
  line-height: 1;
  text-align: left;
}

h3 {
  font-size: 18px;
  line-height: 1;
}

h4 {
  font-size: 16px;
}

a,
button {
  cursor: pointer;
  font-size: 16px;
}

p {
  font-size: 16px;
}

@media (max-width: $medium-screen) {
  h1,
  h2,
  h3,
  h4,
  p,
  small {
    text-align: center;
  }

  h1 {
    font-family: $default-header-font;
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
    line-height: 1.2;
  }

  h3 {
    font-size: 18px;
    line-height: 1;
  }

  h4 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  a,
  button {
    font-size: 14px;
  }

  small {
    font-size: 12px;
  }
}

a {
  color: $default-link-color;
  text-decoration: none;
  transition: color $animation-duration $animation-timing;
}
