@import 'styles/mixins/vars-and-mixins';

.ActiveAlarms {
  line-height: 16px;
  margin-bottom: 40px; /* Allow space for scroll-to-top button */
  h3 {
    text-align: left;
    padding: 15px 10px;
    background-color: var(--norne-main-color-1);
    color: white;
  }
  ul {
    margin: auto;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  li:first-of-type {
    min-width: 150px;
  }
  li:last-of-type {
    min-width: 150px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  b {
    padding: 15px 0px;
    display: block;
    width: 100%;
    margin-left: auto;
  }

  p {
    @include info-box;
    display: block;
  }
}
