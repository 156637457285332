@import 'styles/mixins/vars-and-mixins';

.Portfolio {
  > div {
    margin-top: 20px;
  }
  > h2 {
    margin-top: 0;
  }
  dl {
    display: flex;
    justify-content: space-around;
    margin: 12px 0px;
    padding: 12px 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    dt {
      font-weight: 600;
    }
  }

  table {
    th:first-child,
    td:first-child {
      padding-left: 16px;
      width: 104px;
    }

    th:nth-child(4),
    td:nth-child(4) {
      overflow: hidden;
      white-space: nowrap;
      width: 120px;
    }

    th:last-child,
    td:last-child {
      padding-right: 16px;
      padding-left: 4px;
      white-space: nowrap;
    }

    th:not(:first-child),
    td:not(:first-child) {
      text-align: right;
    }

    @media (max-width: $small-screen) {
      th:first-child,
      td:first-child {
        width: 84px;
      }
      th:nth-child(2),
      td:nth-child(2) {
        padding-right: 10px;
      }
      th:nth-child(4),
      td:nth-child(4) {
        width: 120px;
      }
    }
  }
}
