@import 'styles/mixins/vars-and-mixins';

.fictiveTradeForm {
  padding: 12px;
  .divider {
    background-color: #eaeaea;
    border: 1px solid rgba(151, 151, 151, 0.27);
    height: 10px;
    margin-top: 32px;
    margin-left: -12px;
    margin-right: -12px;
  }

  form {
    > div {
      margin: 20px 0px;
    }
    .formError {
      color: red;
    }
    .orderStatus {
      background-color: var(--norne-main-color-1);
      padding: 15px;

      h3,
      p {
        color: $white;
      }
    }

    input[type='text'],
    input[type='number'] {
      text-align: right;
      width: 100%;
    }
    input[lang='nb'] {
      width: 75px;
    }

    :global(.DayPickerInput) {
      display: block;
      input {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px;
        border: 1px solid #636363;
        border-radius: 3px;
        text-align: right;
        font-size: 16px;
      }
    }
    :global(.DayPickerInput-Overlay) {
      z-index: 99;
    }

    .buttonFlex {
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;
    }
  }
}
