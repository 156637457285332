@mixin gradient($left, $cell, $right) {
  background: linear-gradient(
    90deg,
    $left 0%,
    $cell 15%,
    $cell 85%,
    $right 100%
  );
}

@mixin gradient-last($left, $cell) {
  @include gradient($left, $cell, $cell);
}

@mixin gradient-first($cell, $right) {
  @include gradient($cell, $cell, $right);
}
